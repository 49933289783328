@import "../../mixin";

.section-game{
  width: 300px;
  padding: 10px;
  @include sp{
    width: 100%;
    padding: 20px;
  }
  > form{
    > .topTtl{
      text-align: center;
      font-size: 46px;
      @include pc{
        display: none;
      }
      > span{
        display: inline-block;
      }
      & + h1{
        margin-top: 40px;
        @include pc{
          margin-top: 0;
        }
      }
    }
    > h1{
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-align: center;
      font-size: 46px;
      > input{
        display: inline-block;
        width: 0.8em;
        font-size: 80%;
        &:hover{
          background-color: #666;
        }
        &:focus{
          background-color: #fff;
          color: #000;
        }
      }
      & + .result{
        margin-top: 20px;
      }
    }
    > .result{
      text-align: center;
      font-size: 30px;
      > .star{
        display: block;
      }
      & + h2{
        margin-top: 40px;
      }
    }
    > h2{
      padding: 5px;
      background-color: #ec572b;
      text-align: center;
      font-size: 30px;
      & + .guests{
        margin-top: 20px;
      }
    }
    > .guests{
      > ul{
        > li{
          font-size: 24px;
          & + li{
            margin-top: 5px;
          }
        }
      }
      & + h2{
        margin-top: 40px;
      }
      & + .btn-basic{
        margin-top: 40px;
      }
    }
    > .btn-basic{
      font-size: 20px;
    }
    > .playerCount{
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-size: 24px;
      > input{
        width: 2em;
        margin: 0 5px;
        padding: 5px;
        background-color: #fff;
        text-align: center;
        color: #000;
      }
    }
  }
}
