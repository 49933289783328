@import "../../mixin";

.section-guest{
  width: calc(100% - 300px);
  padding: 10px;
  border-left: 1px dashed #fff;
  @include sp{
    width: 100%;
    margin-top: 20px;
    border-left: none;
    border-top: 1px dashed #fff;
    padding: 40px 20px 20px;
  }
  > h1{
    font-size: 40px;
    @include sp{
      display: none;
    }
    & + h2{
      margin-top: 30px;
      @include sp{
        margin-top: 0;
      }
    }
  }
  > h2{
    font-size: 24px;
    & + .lead{
      margin-top: 10px;
    }
  }
  > .guests{
    > table{

      > thead,tbody{
        > tr{
          &.-disActive{
            opacity: 0.3;
          }
          th,td{
            padding: 5px;
            line-height: 1.6em;
          }
        }
      }
      > thead{
        > tr{
          > th{
            > span{
              cursor: pointer;
              &:hover{
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
    > .noGuest{
      width: 312px;
      padding: 30px;
      text-align: center;
    }
    & +h2{
      margin-top: 30px;
    }
  }
  > .lead{
    line-height: 1.4em;
  }
}
