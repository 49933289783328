body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-basic{
  > ul{
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    > li{
      margin-right: 10px;
      margin-top: 10px;
      &:last-child{
        margin-right: 0;
      }
      > button{
        display: block;
        padding: 5px;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #000;
        &:hover{
          background: #000;
          color: #fff;
        }
        &.-narrow{
          padding: 0 5px;
        }
        &.-ib{
          display: inline-block;
        }
        &.-w100p{
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}

._mt10{
  margin-top: 10px;
}
