@import "../../mixin";

@font-face {
  font-family: Splatoon2;
  src: url(../../fonts/s2kanji.woff2) format('woff2'),
      url(../../fonts/s2kanji.woff) format('woff');
}
@font-face {
  font-family: Splatoon2;
  src: url(../../fonts/s2kana.woff2) format('woff2'),
      url(../../fonts/s2kana.woff) format('woff');
}
.App {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: Splatoon2;
  color: #fff;
  @include sp{
    flex-wrap: wrap;
  }
  > .ad{
    display: block;
    width: 100%;
  }
}

