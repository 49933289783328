.sectionGuestTd {
  position: relative;
  cursor: pointer;
  &:hover{
    background-color: #222;
  }
  > span{
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    > input{
      display: block;
      width: 100%;
      padding: 5px;
      &:focus{
        background-color: #fff;
        color: #000;
      }
    }
  }
  &.-editting{
    > span{
      width: 100%;
      height: 100%;
    }
  }
}

